import * as googleTracker from "./googleTracker"
import * as facebookTracker from "./facebookTracker"
import { isRunTime } from "../util/generalUtil"

export const platformRegister = () => {
  if (isRunTime()) {
    googleTracker.platformRegister()
    facebookTracker.platformRegister()
  }
}
