import { isRunTime } from "../util/generalUtil"

declare global {
  interface Window {
    fbq: any
  }
}

export const platformRegister = () => {
  track("Lead", {})
}

const track = (event: string, object: object) => {
  if (isRunTime() && typeof window.fbq !== "undefined") {
    window.fbq("track", event, object)
  }
}
